<template>
  <div>
    <div class="white-background">
      <div class="flex-row margin-top">
        <img class="pointer-cursor" src="/assets/img/icon-chevron-left-black.svg" v-on:click="close()" />
        <h3 class="h3-title vertical-flex-center margin-left">Vehicle year breakdown</h3>
      </div>
      <div class="results-table-container flex-row margin-top">
        <img src="/assets/img/loader.svg" alt="" class="flex-center" v-if="loadingBreakdown" />
        <div
          class="flex-column padding bordered rounded-corners margin-right highlight-clickable"
          v-on:click="updateYearFilter(year.car_model_year)"
          v-for="year in data"
          v-bind:key="year"
        >
          <p class="body-2-bold no-wrap min-width-192">{{ year.car_model_year }}</p>
          <single-bar-graph
            class="margin-top-2"
            :title="year.car_model_year"
            color="#E5B264"
            :value="year.total"
            :total="totalFormsCount"
          />
        </div>
      </div>
      <search-bar class="margin-right margin-top" availableFilterMode="dealership">
        <secondary-button v-on:click="updateTradedStatusFilter(2)" class="margin-left-05" title="Traded" />
        <secondary-button v-on:click="updateTradedStatusFilter(1)" class="margin-left-05" title="Not Traded" />
        <secondary-button v-on:click="updateTradedStatusFilter(3)" class="margin-left-05" title="Lost" />
        <secondary-button
          v-on:click="updateTradedStatusFilter(4)"
          class="margin-left-05"
          title="Not Traded, Not Lost"
        />
      </search-bar>
      <filter-bar />

      <div class="results-table-container margin-top">
        <results-table availableSearchMode="dealership" :openInNewWindow="true" />
      </div>
    </div>
  </div>
</template>

<script>
import FilterBar from "../FilterBar.vue";
import ResultsTable from "../ResultsTable.vue";
import SearchBar from "../SearchBar.vue";
import SingleBarGraph from "../SingleBarGraph.vue";
import SecondaryButton from "../../../Buttons/v2/SecondaryButton.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "YearReportPage",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loadingBreakdown: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchBar,
    FilterBar,
    ResultsTable,
    SingleBarGraph,
    SecondaryButton
  },
  data: function () {
    return {
      axiosCancelToken: null,
      breakdown: []
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    updateYearFilter: function (filter) {
      let years = filter.split("-");
      if (years.length == 2) {
        let gt = parseInt(years[0]);
        let lt = parseInt(years[1]);
        if (gt) {
          this.addFilter({
            filter: {
              key: "car_model_year_gt",
              value: gt
            }
          });
        } else {
          this.removeFilter({
            filter: {
              key: "car_model_year_gt"
            }
          });
          if (lt) {
            lt -= 1;
          }
        }
        if (lt) {
          this.addFilter({
            filter: {
              key: "car_model_year_lt",
              value: lt
            }
          });
        } else {
          this.removeFilter({
            filter: {
              key: "car_model_year_lt"
            }
          });
        }
      }
    },
    updateTradedStatusFilter: function (status) {
      this.addFilter({
        filter: {
          key: "traded_status",
          value: status
        }
      });
    },
    ...mapActions({
      addFilter: "formStore/addFilter",
      removeFilter: "formStore/removeFilter"
    })
  },
  computed: {
    ...mapState({
      totalFormsCount: state => state.formStore.totalFormsCount
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
