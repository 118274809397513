<template>
  <div>
    <div class="white-background">
      <div class="flex-row margin-top">
        <img class="pointer-cursor" src="/assets/img/icon-chevron-left-black.svg" v-on:click="close()" />
        <h3 class="h3-title vertical-flex-center margin-left">Purity percentage breakdown</h3>
      </div>
      <div class="results-table-container flex-row margin-top">
        <img src="/assets/img/loader.svg" alt="" class="flex-center" v-if="loadingBreakdown" />
        <div
          class="flex-column padding bordered rounded-corners margin-right highlight-clickable"
          v-for="dealer in breakdown"
          v-bind:key="dealer"
          v-on:click="updateDealerFilter(dealer.dealer.id)"
        >
          <p class="body-2-bold no-wrap min-width-192">{{ dealer.dealer.first_name }} {{ dealer.dealer.last_name }}</p>
          <single-bar-graph
            class="margin-top-2"
            title="Purity"
            color="#27A1FF"
            :hideTotalsLabel="true"
            :value="dealer.purity"
            :total="100"
          />
        </div>
      </div>
      <search-bar class="margin-right margin-top" availableFilterMode="dealership">
        <secondary-button v-on:click="updateTradedStatusFilter(2)" class="margin-left-05" title="Traded" />
        <secondary-button v-on:click="updateTradedStatusFilter(1)" class="margin-left-05" title="Not Traded" />
        <secondary-button v-on:click="updateTradedStatusFilter(3)" class="margin-left-05" title="Lost" />
        <secondary-button
          v-on:click="updateTradedStatusFilter(4)"
          class="margin-left-05"
          title="Not Traded, Not Lost"
        />
      </search-bar>
      <filter-bar />

      <div class="results-table-container margin-top">
        <results-table availableSearchMode="reports" :openInNewWindow="true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import SecondaryButton from "../../../Buttons/v2/SecondaryButton.vue";
import FilterBar from "../FilterBar.vue";
import ResultsTable from "../ResultsTable.vue";
import SearchBar from "../SearchBar.vue";
import SingleBarGraph from "../SingleBarGraph.vue";

import { mapActions, mapGetters } from "vuex";
import { getReportsBreakdown } from "../../../../api/v2/Reports.js";

export default {
  name: "PurityReportPage",
  components: {
    SearchBar,
    FilterBar,
    ResultsTable,
    SecondaryButton,
    SingleBarGraph
  },
  data: function () {
    return {
      loadingBreakdown: false,
      axiosCancelToken: null,
      breakdown: []
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    updateTradedStatusFilter: function (status) {
      this.addFilter({
        filter: {
          key: "traded_status",
          value: status
        }
      });
    },
    updateDealerFilter: function (dealerID) {
      this.addFilter({
        filter: {
          key: "starting_dealer",
          value: dealerID
        }
      });
    },
    getBreakdown: function () {
      this.breakdown = [];
      if (this.axiosCancelToken) {
        if (this.axiosCancelToken.cancel) {
          this.axiosCancelToken.cancel("New Request");
        }
      }
      this.axiosCancelToken = axios.CancelToken.source();
      this.loadingBreakdown = true;
      getReportsBreakdown(this.filters, this.axiosCancelToken, "dealer-purity-breakdown")
        .then(response => {
          this.loadingBreakdown = false;
          this.breakdown = response.breakdown;
        })
        .catch(error => {
          if (!error.__CANCEL__) {
            this.loadingBreakdown = false;
          }
        });
    },
    ...mapActions({
      addFilter: "formStore/addFilter"
    })
  },
  computed: {
    ...mapGetters({
      filters: "formStore/getFilters"
    })
  },
  mounted: function () {
    this.getBreakdown();
  },
  unmounted: function () {
    if (this.axiosCancelToken) {
      if (this.axiosCancelToken.cancel) {
        this.axiosCancelToken.cancel("New Request");
      }
    }
  },
  watch: {
    "$store.state.formStore.filters": {
      deep: true,
      handler() {
        this.getBreakdown();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
