<template>
  <div>
    <div class="white-background">
      <div class="flex-row margin-top">
        <img class="pointer-cursor" src="/assets/img/icon-chevron-left-black.svg" v-on:click="close()" />
        <h3 class="h3-title vertical-flex-center margin-left">Vehicle make breakdown</h3>
      </div>
      <div class="results-table-container flex-row margin-top">
        <img src="/assets/img/loader.svg" alt="" class="flex-center" v-if="loadingBreakdown" />
        <div
          class="flex-column padding bordered rounded-corners margin-right highlight-clickable"
          v-on:click="updateMakeFilter(make.make)"
          v-for="make in data"
          v-bind:key="make"
        >
          <p class="body-2-bold no-wrap min-width-192">{{ make.make }}</p>
          <single-bar-graph
            class="margin-top-2"
            :title="make.make"
            color="#E5B264"
            :value="make.total"
            :total="totalFormsCount"
          />
        </div>
      </div>
      <search-bar class="margin-right margin-top" availableFilterMode="dealership">
        <secondary-button v-on:click="updateTradedStatusFilter(2)" class="margin-left-05" title="Traded" />
        <secondary-button v-on:click="updateTradedStatusFilter(1)" class="margin-left-05" title="Not Traded" />
        <secondary-button v-on:click="updateTradedStatusFilter(3)" class="margin-left-05" title="Lost" />
        <secondary-button
          v-on:click="updateTradedStatusFilter(4)"
          class="margin-left-05"
          title="Not Traded, Not Lost"
        />
      </search-bar>
      <filter-bar />

      <div class="results-table-container margin-top">
        <results-table availableSearchMode="dealership" :openInNewWindow="true" />
      </div>
    </div>
  </div>
</template>

<script>
import FilterBar from "../FilterBar.vue";
import ResultsTable from "../ResultsTable.vue";
import SearchBar from "../SearchBar.vue";
import SingleBarGraph from "../SingleBarGraph.vue";
import SecondaryButton from "../../../Buttons/v2/SecondaryButton.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "MakeReportPage",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loadingBreakdown: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchBar,
    FilterBar,
    ResultsTable,
    SingleBarGraph,
    SecondaryButton
  },
  data: function () {
    return {
      axiosCancelToken: null,
      breakdown: []
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    updateMakeFilter: function (make) {
      this.addFilter({
        filter: {
          key: "car_make",
          value: make
        }
      });
    },
    updateTradedStatusFilter: function (status) {
      this.addFilter({
        filter: {
          key: "traded_status",
          value: status
        }
      });
    },
    ...mapActions({
      addFilter: "formStore/addFilter"
    })
  },
  computed: {
    ...mapState({
      totalFormsCount: state => state.formStore.totalFormsCount
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
