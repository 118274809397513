<template>
  <div>
    <div id="reports-page">
      <h1 class="h1-title">Analytics</h1>
      <div class="position-relative">
        <div v-if="!overlayShowing">
          <div>
            <search-bar class="margin-right" availableFilterMode="dealership" />
            <filter-bar />
          </div>
          <div
            v-if="overviewHandler.loading"
            class="flex-column padding-2 bordered margin-right-2 margin-top rounded-corners"
          >
            <h3 class="h3-title">Overview</h3>
            <img src="/assets/img/loader.svg" alt="" class="flex-center" />
          </div>
          <div
            v-if="overviewHandler.data && !overviewHandler.loading"
            class="padding-2 bordered margin-right-2 margin-top rounded-corners"
          >
            <h3 class="h3-title">Overview</h3>
            <div class="overview-grid">
              <div class="flex-row highlight-clickable" v-on:click="showCompletionPercentagePage = true">
                <div class="flex-column flex-grow">
                  <apexchart
                    type="radialBar"
                    width="100%"
                    :options="getConversionPercentageData.options"
                    :series="getConversionPercentageData.series"
                  ></apexchart>
                  <p class="body-2-body flex-center">Traded</p>
                </div>
                <div class="flex-column flex-grow">
                  <apexchart
                    type="radialBar"
                    width="100%"
                    :options="getConversionPercentageDataForLost.options"
                    :series="getConversionPercentageDataForLost.series"
                  ></apexchart>
                  <p class="body-2-body flex-center">Lost</p>
                </div>
                <div class="flex-column flex-grow">
                  <apexchart
                    type="radialBar"
                    width="100%"
                    :options="getConversionPercentageDataForNotTradedNotLost.options"
                    :series="getConversionPercentageDataForNotTradedNotLost.series"
                  ></apexchart>
                  <p class="body-2-body flex-center">Not Traded Not Lost</p>
                </div>
              </div>
              <div class="flex-row highlight-clickable" v-on:click="showPurityPercentagePage = true">
                <div class="flex-column">
                  <apexchart
                    type="radialBar"
                    width="100%"
                    :options="getPurityPercentageData.options"
                    :series="getPurityPercentageData.series"
                  ></apexchart>
                  <p class="body-2-body flex-center">Average Purity</p>
                </div>
              </div>
              <div class="vertical-seperator"></div>
              <div
                class="flex-column flex-grow highlight-clickable padding-top padding-left padding-right"
                v-on:click="showTradedPurposePage = true"
              >
                <single-bar-graph
                  class="flex-grow"
                  title="Wholesale"
                  color="#E5B264"
                  :value="overviewHandler.data.traded_wholesale_count"
                  :total="overviewHandler.data.traded_count"
                />
                <single-bar-graph
                  class="flex-grow"
                  title="Retail"
                  color="#4667BE"
                  :value="overviewHandler.data.traded_retail_count"
                  :total="overviewHandler.data.traded_count"
                />
                <single-bar-graph
                  class="flex-grow"
                  title="Undecided"
                  color="#27A1FF"
                  :value="overviewHandler.data.traded_undecided_count"
                  :total="overviewHandler.data.traded_count"
                />
                <single-bar-graph
                  class="flex-grow"
                  title="Budget"
                  color="#8661C1"
                  :value="overviewHandler.data.traded_budget_count"
                  :total="overviewHandler.data.traded_count"
                />
                <p class="body-2-body horizontal-flex-center margin-top">Traded Purpose</p>
              </div>
            </div>
          </div>

          <div class="flex-row flex-grow margin-top-2 margin-right-2">
            <div
              v-if="yearHandler.loading"
              class="bordered margin-right padding-2 flex-grow flex-column rounded-corners"
            >
              <h3 class="h3-title">Vehicle year breakdown</h3>
              <img v-if="yearHandler.loading" src="/assets/img/loader.svg" alt="" class="flex-center" />
            </div>
            <div
              v-if="!yearHandler.loading"
              class="bordered margin-right padding-2 flex-grow flex-column highlight-clickable rounded-corners"
              v-on:click="showYearsPage = true"
            >
              <h3 class="h3-title">Vehicle year breakdown</h3>
              <div v-if="yearHandler.data.length > 0">
                <apexchart
                  type="bar"
                  :options="getVehicleYearBreakdownData.options"
                  :series="getVehicleYearBreakdownData.series"
                ></apexchart>
              </div>
            </div>
            <div
              v-if="priceHandler.loading"
              class="bordered margin-left padding-2 flex-grow flex-column rounded-corners"
            >
              <h3 class="h3-title">Vehicle price breakdown</h3>
              <img v-if="priceHandler.loading" src="/assets/img/loader.svg" alt="" class="flex-center" />
            </div>
            <div
              v-if="!priceHandler.loading"
              class="bordered margin-left padding-2 flex-grow flex-column highlight-clickable rounded-corners"
              v-on:click="showPricePage = true"
            >
              <h3 class="h3-title">Vehicle price breakdown</h3>
              <div v-if="priceHandler.data.length > 0">
                <apexchart
                  type="bar"
                  :options="getVehiclePriceBreakdownData.options"
                  :series="getVehiclePriceBreakdownData.series"
                ></apexchart>
              </div>
            </div>
          </div>

          <div class="flex-row flex-grow margin-top-2 margin-right-2">
            <div
              v-if="resellerHandler.loading"
              class="bordered margin-right padding-2 flex-grow flex-column rounded-corners"
            >
              <h3 class="h3-title">Wholesaler breakdown</h3>
              <img src="/assets/img/loader.svg" alt="" class="flex-center" />
            </div>
            <div
              v-if="!resellerHandler.loading"
              class="bordered margin-right padding-2 flex-grow flex-column rounded-corners"
            >
              <h3 class="h3-title">Wholesaler breakdown</h3>
              <div v-if="resellerHandler.data.length > 0">
                <apexchart
                  type="bar"
                  :options="getWholesalerBreakdownData.options"
                  :height="Math.max(resellerHandler.data.length * 60, 180) + 'px'"
                  :series="getWholesalerBreakdownData.series"
                ></apexchart>
              </div>
            </div>
            <div
              v-if="makeHandler.loading"
              class="bordered margin-left padding-2 flex-grow flex-column margin-bottom-auto rounded-corners"
            >
              <h3 class="h3-title">Vehicle make breakdown</h3>
              <img src="/assets/img/loader.svg" alt="" class="flex-center" />
            </div>
            <div
              v-if="!makeHandler.loading"
              class="
                bordered
                margin-left
                padding-2
                flex-grow flex-column
                margin-bottom-auto
                highlight-clickable
                rounded-corners
              "
              v-on:click="showMakePage = true"
            >
              <h3 class="h3-title">Vehicle make breakdown</h3>
              <div v-if="makeHandler.data.length > 0">
                <apexchart
                  type="bar"
                  :options="getVehicleMakeBreakdownData.options"
                  :height="Math.max(makeHandler.data.length * 40, 120) + 'px'"
                  :series="getVehicleMakeBreakdownData.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>

        <conversion-report-page v-if="showCompletionPercentagePage" @close="shouldHideModals()" />
        <purity-report-page v-if="showPurityPercentagePage" @close="shouldHideModals()" />
        <traded-purpose-report-page v-if="showTradedPurposePage" @close="shouldHideModals()" />
        <year-report-page
          v-if="showYearsPage"
          @close="shouldHideModals()"
          :data="yearHandler.data"
          :loadingBreakdown="yearHandler.loading"
        />
        <price-report-page
          v-if="showPricePage"
          @close="shouldHideModals()"
          :data="priceHandler.data"
          :loadingBreakdown="priceHandler.loading"
        />
        <make-report-page
          v-if="showMakePage"
          @close="shouldHideModals()"
          :data="makeHandler.data"
          :loadingBreakdown="makeHandler.loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { endpoints } from "../../../api/axiosHandler";

import { getReportsOverview, getReportsBreakdown } from "../../../api/v2/Reports.js";
import {
  getConversionPercentageGraphingData,
  getPurityPercentageGraphingData
} from "../../../helpers/v2/reportHelpers.js";
import { setStringDateToStartOfDate } from "../../../helpers/utilityHelpers";

import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../../components/Views/v2/FilterBar.vue";
import SingleBarGraph from "../../../components/Views/v2/SingleBarGraph.vue";
import ConversionReportPage from "../../../components/Views/v2/Reports/ConversionReportPage.vue";
import PurityReportPage from "../../../components/Views/v2/Reports/PurityReportPage.vue";
import TradedPurposeReportPage from "../../../components/Views/v2/Reports/TradedPurposeReportPage.vue";
import YearReportPage from "../../../components/Views/v2/Reports/YearReportPage.vue";
import PriceReportPage from "../../../components/Views/v2/Reports/PriceReportPage.vue";
import MakeReportPage from "../../../components/Views/v2/Reports/MakeReportPage.vue";

export default {
  name: "Reports",
  components: {
    apexchart: VueApexCharts,
    SearchBar,
    FilterBar,
    SingleBarGraph,
    ConversionReportPage,
    PurityReportPage,
    TradedPurposeReportPage,
    YearReportPage,
    PriceReportPage,
    MakeReportPage
  },
  data: function () {
    return {
      showCompletionPercentagePage: false,
      showPurityPercentagePage: false,
      showTradedPurposePage: false,
      showYearsPage: false,
      showPricePage: false,
      showMakePage: false,
      filtersUpdated: false,

      overviewHandler: {
        cancelToken: null,
        loading: false,
        data: null
      },
      yearHandler: {
        cancelToken: null,
        loading: false,
        data: []
      },
      priceHandler: {
        cancelToken: null,
        loading: false,
        data: []
      },
      resellerHandler: {
        cancelToken: null,
        loading: false,
        data: []
      },
      makeHandler: {
        cancelToken: null,
        loading: false,
        data: []
      }
    };
  },
  methods: {
    getReports: function () {
      this.getOverviewBreakdown();
      this.getYearBreakdown();
      this.getPriceBreakdown();
      this.getResellerBreakdown();
      this.getMakesBreakdown();
    },
    getOverviewBreakdown: function () {
      if (this.overviewHandler.cancelToken) {
        if (this.overviewHandler.cancelToken.cancel) {
          this.overviewHandler.cancelToken.cancel("New Request");
        }
      }
      this.overviewHandler.cancelToken = axios.CancelToken.source();
      this.overviewHandler.data = null;
      this.overviewHandler.loading = true;
      let app = this;
      app.$nextTick(() => {
        getReportsOverview(app.filters, app.overviewHandler.cancelToken)
          .then(response => {
            app.overviewHandler.loading = false;
            app.overviewHandler.data = response;
          })
          .catch(error => {
            if (!error.__CANCEL__) {
              app.overviewHandler.loading = false;
            }
          });
      });
    },
    getYearBreakdown: function () {
      if (this.yearHandler.cancelToken) {
        if (this.yearHandler.cancelToken.cancel) {
          this.yearHandler.cancelToken.cancel("New Request");
        }
      }
      this.yearHandler.cancelToken = axios.CancelToken.source();
      this.yearHandler.data = [];
      this.yearHandler.loading = true;
      let app = this;
      app.$nextTick(() => {
        app.$nextTick(() => {
          getReportsBreakdown(app.filters, app.yearHandler.cancelToken, "year-breakdown")
            .then(response => {
              app.yearHandler.loading = false;
              app.yearHandler.data = response.breakdown.year;
            })
            .catch(error => {
              if (!error.__CANCEL__) {
                app.loading = false;
              }
            });
        });
      });
    },
    getPriceBreakdown: function () {
      if (this.priceHandler.cancelToken) {
        if (this.priceHandler.cancelToken.cancel) {
          this.priceHandler.cancelToken.cancel("New Request");
        }
      }
      this.priceHandler.cancelToken = axios.CancelToken.source();
      this.priceHandler.data = [];
      this.priceHandler.loading = true;
      let app = this;
      app.$nextTick(() => {
        getReportsBreakdown(app.filters, app.priceHandler.cancelToken, "price-breakdown")
          .then(response => {
            app.priceHandler.loading = false;
            app.priceHandler.data = response.breakdown.price
              .filter(dataPiece => {
                return dataPiece.price != null;
              })
              .sort((a, b) => this.removeNonNumbers(a.price) - this.removeNonNumbers(b.price));
          })
          .catch(error => {
            if (!error.__CANCEL__) {
              app.priceHandler.loading = false;
            }
          });
      });
    },
    removeNonNumbers: function (string) {
      string = string.replace("+", "000");
      return string.replace(/\D/g, "");
    },
    getResellerBreakdown: function () {
      if (this.resellerHandler.cancelToken) {
        if (this.resellerHandler.cancelToken.cancel) {
          this.resellerHandler.cancelToken.cancel("New Request");
        }
      }
      this.resellerHandler.cancelToken = axios.CancelToken.source();
      this.resellerHandler.loading = true;
      this.resellerHandler.data = [];
      let app = this;
      app.$nextTick(() => {
        getReportsBreakdown(app.filters, app.resellerHandler.cancelToken, "reseller-breakdown")
          .then(response => {
            app.resellerHandler.loading = false;
            app.resellerHandler.data = response.breakdown.wholesaler;
          })
          .catch(error => {
            if (!error.__CANCEL__) {
              app.resellerHandler.loading = false;
            }
          });
      });
    },
    getMakesBreakdown: function () {
      if (this.makeCancelToken) {
        if (this.makeCancelToken.cancel) {
          this.makeCancelToken.cancel("New Request");
        }
      }
      this.makeCancelToken = axios.CancelToken.source();
      this.makeHandler.loading = true;
      this.makeHandler.data = [];
      let app = this;
      app.$nextTick(() => {
        getReportsBreakdown(app.filters, app.makeCancelToken, "make-breakdown")
          .then(response => {
            app.makeHandler.loading = false;
            app.makeHandler.data = response.breakdown.make;
          })
          .catch(error => {
            console.log(error);
            if (!error.__CANCEL__) {
              app.makeHandler.loading = false;
            }
          });
      });
    },
    shouldHideModals: function () {
      this.showCompletionPercentagePage = false;
      this.showPurityPercentagePage = false;
      this.showTradedPurposePage = false;
      this.showYearsPage = false;
      this.showPricePage = false;
      this.showMakePage = false;
    },
    verifyCorrectFormsUrl: function () {
      if (this.currentFormsUrl != endpoints.form) {
        this.setFormsUrl(endpoints.form);
        this.getForms({});
      }
    },
    ...mapActions({
      addFilter: "formStore/addFilter",
      setFormsUrl: "formStore/setCurrentUrl",
      getForms: "formStore/getForms"
    })
  },
  computed: {
    overlayShowing() {
      return (
        this.showCompletionPercentagePage ||
        this.showPurityPercentagePage ||
        this.showTradedPurposePage ||
        this.showYearsPage ||
        this.showPricePage ||
        this.showMakePage
      );
    },
    getConversionPercentageDataForNotTradedNotLost() {
      return getConversionPercentageGraphingData(
        this.overviewHandler.data.not_traded_not_lost_count,
        this.overviewHandler.data.total,
        "#E5B264"
      );
    },
    getConversionPercentageDataForLost() {
      return getConversionPercentageGraphingData(
        this.overviewHandler.data.lost_count,
        this.overviewHandler.data.total,
        "#4667BE"
      );
    },
    getConversionPercentageData() {
      return getConversionPercentageGraphingData(
        this.overviewHandler.data.traded_count,
        this.overviewHandler.data.total,
        "#27A1FF"
      );
    },
    getPurityPercentageData() {
      return getPurityPercentageGraphingData(this.overviewHandler.data.purity);
    },
    getVehicleYearBreakdownData() {
      return {
        series: [
          {
            name: "Total",
            data: this.yearHandler.data.map(yearObject => {
              return yearObject.total;
            })
          }
        ],
        options: {
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            categories: this.yearHandler.data.map(yearObject => {
              return yearObject.car_model_year;
            })
          }
        }
      };
    },
    getVehiclePriceBreakdownData() {
      return {
        series: [
          {
            name: "Total",
            data: this.priceHandler.data.map(priceObject => {
              return priceObject.total;
            })
          }
        ],
        options: {
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            categories: this.priceHandler.data.map(priceObject => {
              return priceObject.price;
            })
          }
        }
      };
    },
    getWholesalerBreakdownData() {
      return {
        series: [
          {
            name: "Offered",
            data: this.resellerHandler.data.map(wholesalerObject => {
              return wholesalerObject.offered;
            })
          },
          {
            name: "Won",
            data: this.resellerHandler.data.map(wholesalerObject => {
              return wholesalerObject.won;
            })
          },
          {
            name: "Passed",
            data: this.resellerHandler.data.map(wholesalerObject => {
              return wholesalerObject.passed;
            })
          }
        ],
        options: {
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: this.resellerHandler.data.map(wholesalerObject => {
              return wholesalerObject.reseller.split(" ");
            })
          }
        }
      };
    },
    getVehicleMakeBreakdownData() {
      return {
        series: [
          {
            name: "Make",
            data: this.makeHandler.data.map(makeObject => {
              return makeObject.total;
            })
          }
        ],
        options: {
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: this.makeHandler.data.map(makeObject => {
              return makeObject.make;
            })
          }
        }
      };
    },
    ...mapGetters({
      filters: "formStore/getFilters",
      dealers: "dealershipStore/dealers",
      currentFormsUrl: "formStore/getCurrentUrl"
    })
  },
  watch: {
    "$store.state.formStore.filters": {
      deep: true,
      handler() {
        if (!this.overlayShowing) {
          this.getReports();
        } else {
          this.filtersUpdated = true;
          if (this.showYearsPage) {
            this.getYearBreakdown();
          } else if (this.showPricePage) {
            this.getPriceBreakdown();
          } else if (this.showMakePage) {
            this.getMakesBreakdown();
          }
        }
      }
    },
    overlayShowing: function () {
      this.verifyCorrectFormsUrl();
      if (this.filtersUpdated) {
        this.filtersUpdated = false;
        this.getReports();
      }
    }
  },
  mounted: function () {
    var today = new Date();
    let searchStartDate = new Date(new Date().setDate(today.getDate() - 30));
    if (searchStartDate) {
      let date = setStringDateToStartOfDate(searchStartDate);
      if (date) {
        this.addFilter({
          filter: {
            key: "date_time_created_gt",
            value: date.toISOString()
          }
        });
      }
    } else {
      this.removeFilterPrep({ filter: { key: "date_time_created_gt" } });
    }
    this.getReports();
  },
  unmounted: function () {
    if (this.overviewHandler.cancelToken) {
      if (this.overviewHandler.cancelToken.cancel) {
        this.overviewHandler.cancelToken.cancel("New Request");
      }
    }
    if (this.yearHandler.cancelToken) {
      if (this.yearHandler.cancelToken.cancel) {
        this.yearHandler.cancelToken.cancel("New Request");
      }
    }
    if (this.priceHandler.cancelToken) {
      if (this.priceHandler.cancelToken.cancel) {
        this.priceHandler.cancelToken.cancel("New Request");
      }
    }
    if (this.resellerHandler.cancelToken) {
      if (this.resellerHandler.cancelToken.cancel) {
        this.resellerHandler.cancelToken.cancel("New Request");
      }
    }
    if (this.makeHandler.cancelToken) {
      if (this.makeHandler.cancelToken.cancel) {
        this.makeHandler.cancelToken.cancel("New Request");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#reports-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.vertical-seperator {
  @extend .margin-right-2-05;
  background-color: $CARD_BACKGROUND_COLOR;
  width: 2px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overview-grid {
  display: grid;
  grid-template-columns: 3fr 1fr 1px 1fr;
}
</style>
