function getConversionPercentageGraphingData(count, total, colour) {
  return {
    series: [total > 0 ? parseInt((count / total) * 100) : 0],
    options: {
      labels: [count + "/" + total],
      fill: {
        colors: [colour]
      }
    }
  };
}

function getPurityPercentageGraphingData(percentage) {
  return {
    series: [parseInt(percentage)],
    options: {
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "24px",
              offsetY: 5
            },
            value: {
              show: false
            }
          }
        }
      },
      fill: {
        colors: ["#27A1FF"]
      },
      labels: [parseInt(percentage) + "%"]
    }
  };
}

export { getConversionPercentageGraphingData, getPurityPercentageGraphingData };
