<template>
  <div>
    <div class="white-background">
      <div class="flex-row margin-top">
        <img class="pointer-cursor" src="/assets/img/icon-chevron-left-black.svg" v-on:click="close()" />
        <h3 class="h3-title vertical-flex-center margin-left">Vehicle price breakdown</h3>
      </div>
      <div class="results-table-container flex-row margin-top">
        <img src="/assets/img/loader.svg" alt="" class="flex-center" v-if="loadingBreakdown" />
        <div class="flex-column padding bordered rounded-corners margin-right" v-for="price in data" v-bind:key="price">
          <p class="body-2-bold no-wrap min-width-192">{{ price.price }}</p>
          <single-bar-graph
            class="margin-top-2"
            :title="price.price"
            color="#E5B264"
            :value="price.total"
            :total="totalFormsCount"
          />
        </div>
      </div>
      <search-bar class="margin-right margin-top" availableFilterMode="dealership"> </search-bar>
      <filter-bar />

      <div class="results-table-container margin-top">
        <results-table availableSearchMode="dealership" :openInNewWindow="true" />
      </div>
    </div>
  </div>
</template>

<script>
import FilterBar from "../FilterBar.vue";
import ResultsTable from "../ResultsTable.vue";
import SearchBar from "../SearchBar.vue";
import SingleBarGraph from "../SingleBarGraph.vue";

import { mapState } from "vuex";

export default {
  name: "PriceReportPage",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loadingBreakdown: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SearchBar,
    FilterBar,
    ResultsTable,
    SingleBarGraph
  },
  data: function () {
    return {
      axiosCancelToken: null,
      breakdown: []
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    }
  },
  computed: {
    ...mapState({
      totalFormsCount: state => state.formStore.totalFormsCount
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
