<template>
  <div class="single-bar-graph body-2">
    <div class="flex-row margin-bottom">
      <p class="body-2 margin-right-auto">{{ title }}</p>
      <p class="body-2-bold">{{ total > 0 ? parseInt((value / total) * 100) : 0 }}%</p>
      <p class="margin-left-025" v-if="getShowTotalsLabel()">{{ value }}/{{ total }}</p>
    </div>
    <div class="graph-container">
      <div class="graph-bg total-rounded">
        <div
          class="graph rounded-corners"
          :style="{
            width: (total > 0 ? parseInt((value / total) * 100) : 0) + '%',
            backgroundColor: color
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleBarGraph",
  props: ["value", "total", "title", "color", "hideTotalsLabel"],
  methods: {
    getShowTotalsLabel: function () {
      if (this.hideTotalsLabel == null || !this.hideTotalsLabel) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
.graph-container {
  width: 100%;
  height: 16px;
  position: relative;

  .graph {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .graph-bg {
    @extend .graph;

    height: 100%;
    width: 100%;
    background-color: rgba($color: #bbb, $alpha: 0.7);
    overflow: hidden;
  }
}

.total-rounded {
  border-radius: 10px;
}
</style>
